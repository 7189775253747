import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import Toast from "../components/Toast";
import Web from "../layouts/Web";
import Careers from "../views/Careers";
import Home from "../views/Home";
import MobileDevelopment from "../views/MobileDevelopment";
import Portfolio from "../views/Portfolio";
import Process from "../views/Process";
import UIUXDesigns from "../views/UIUXDesigns";
import WebDevelopment from "../views/WebDevelopment";
import AboutUs from "../views/AboutUs";
import IndividualPortfolio from "../views/IndividualPortfolio";
import ApplyJobs from "../views/ApplyJob";
import ContactUs from "../views/ContactUs";
import Toast from "../components/Toast";

function Main(props) {
  const [message, setMessage] = useState({ s: "", t: "" });

  useEffect(() => {
    window.location.replace("https://www.upview.tech");
  }, []);

  return (
    <>
      {/* <Router>
        <div className="h-full">
          <Switch>
            <Web>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/process">
                <Process />
              </Route>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
              <Route path="/aboutus">
                <AboutUs />
              </Route>
              <Route path="/careers">
                <Careers />
              </Route>
              <Route path="/individual-portfolio">
                <IndividualPortfolio />
              </Route>
              <Route path="/apply-jobs">
                <ApplyJobs onMessageSent={(mes) => setMessage(mes)} />
              </Route>
              <Route path="/contact">
                <ContactUs onMessageSent={(mes) => setMessage(mes)} />
              </Route>
              <Route path="/web-development">
                <WebDevelopment />
              </Route>
              <Route path="/mobile-development">
                <MobileDevelopment />
              </Route>
              <Route path="/uiux-designs">
                <UIUXDesigns />
              </Route>
              <Redirect from="/" to="/home" />
            </Web>
          </Switch>
        </div>
      </Router>
      <Toast message={message} /> */}
    </>
  );
}

export default Main;
