import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import logo from "./../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import web from "./../../assets/images/web.svg";
import ui from "./../../assets/images/ui.svg";
import mobile from "./../../assets/images/mobile.svg";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [serviceMenu, setServiceMenu] = useState(true);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 320) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const menu = (
    <Menu style={{ paddingBlock: "1.25rem", marginTop: "1rem" }}>
      <Link to="web-development" onClick={() => setServiceMenu(false)}>
        <div className="flex  gap-5 px-5 py-2 hover:bg-gray-200">
          <div>
            <img
              className="h-6 w-6"
              src={web}
              alt="web application development"
            />
          </div>
          <div>
            <div className="text-pri-500 text-sm">Web App Development</div>
            <div className="text-gray-900 max-w-xs text-xs">
              Create beautiful, fast and secure web applications tailored
              exclusively for your business goals.
            </div>
          </div>
        </div>
      </Link>

      <Link to="mobile-development" onClick={() => setServiceMenu(false)}>
        <div className="flex gap-5 px-5 py-2 hover:bg-gray-200">
          <div>
            <img
              className="h-6 w-6"
              src={mobile}
              alt="mobile application development"
            />
          </div>
          <div>
            <div className="text-pri-500 text-sm">Mobile App Development</div>
            <div className="text-gray-900 max-w-xs text-xs">
              Build well-designed and optimized custom mobile applications for
              both iOS and Android.
            </div>
          </div>
        </div>
      </Link>

      <Link to="uiux-designs" onClick={() => setServiceMenu(false)}>
        <div className="flex gap-5 px-5 py-2 hover:bg-gray-200">
          <div>
            <img className="h-6 w-6" src={ui} alt="ui/ux designs" />
          </div>
          <div>
            <div className="text-pri-500 text-sm">UI/UX Designs</div>
            <div className="text-gray-900 max-w-xs text-xs">
              Compelling, Interactive, and intuitive Digital Experiences for Web
              and Mobile Apps.
            </div>
          </div>
        </div>
      </Link>
    </Menu>
  );

  const serviceTypes = (
    <>
      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpand(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="web-development"
      >
        Web App Development
      </Link>

      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpand(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="mobile-development"
      >
        Mobile App Development
      </Link>

      <Link
        onClick={() => {
          setMobileMenu(false);
          setExpand(false);
        }}
        className="block ml-4 lg:ml-6 my-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
        to="uiux-designs"
      >
        UI/UX Designs
      </Link>
    </>
  );

  return (
    <div
      className={
        "bg-pri-50 px-3 py-4 w-full bg-white z-40 " +
        (scrolled ? "fixed shadow-md" : "absolute")
      }
      style={!scrolled ? { marginTop: "38px" } : { marginTop: "0px" }}
    >
      <div className="container">
        <div className="flex justify-between items-center">
          <Link
            onClick={() => setMobileMenu(false)}
            to="home"
            className="cursor-pointer"
          >
            <div className="flex items-center">
              <img className="h-8" src={logo} alt="Kodplex Logo" />
              <div
                style={{ fontSize: "41px", paddingTop: "4px" }}
                className="teko text-pri-500 -my-16 ml-1"
              >
                upview
              </div>
            </div>
          </Link>
          <div>
            <div className="items-center hidden md:flex">
              <Dropdown
                overlay={serviceMenu ? menu : ""}
                placement="bottomRight"
              >
                <div
                  onMouseOver={() => setServiceMenu(true)}
                  className="flex items-center mx-2 lg:mx-3 whitespace-nowrap text-base text-pri-500 cursor-pointer hover:text-sec-500"
                >
                  Services &nbsp;
                  <DownOutlined />
                </div>
              </Dropdown>

              <Link
                to="portfolio"
                className="mx-2 lg:mx-3 text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                Portfolio
              </Link>
              <Link
                to="careers"
                className="mx-2 lg:mx-3 text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                Careers
              </Link>
              <Link
                to="aboutus"
                className="mx-2 lg:mx-3 text-base text-pri-500 cursor-pointer hover:text-sec-500"
              >
                About Us
              </Link>

              <Link
                to="contact"
                className="btn3 ml-2 lg:ml-3 text-base bg-white text-pri-500 border-2 border-pri-500 py-2 px-5 rounded-sm cursor-pointer hover:text-white"
              >
                Contact Us
              </Link>
            </div>
            <div
              onClick={() => setMobileMenu(!mobileMenu)}
              className="md:hidden"
            >
              <FeatherIcon icon="menu" className="w-8 h-8" />
            </div>
          </div>
        </div>

        {mobileMenu && (
          <div className="mt-3">
            <div
              onClick={() => {
                setExpand(!expand);
              }}
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Services{" "}
              <FeatherIcon className=" w-5 h-5 inline " icon="chevron-down" />
            </div>
            <div>{expand === true && serviceTypes}</div>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpand(false);
              }}
              to="portfolio"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Portfolio
            </Link>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpand(false);
              }}
              to="careers"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              Careers
            </Link>

            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpand(false);
              }}
              to="aboutus"
              className="block m-2 text-base text-pri-500 cursor-pointer hover:text-sec-500"
            >
              About Us
            </Link>
            <Link
              onClick={() => {
                setMobileMenu(false);
                setExpand(false);
              }}
              to="contact"
              className="block m-2 text-base text-pri-900 border-2 border-pri-500 py-2 px-5 rounded-sm cursor-pointer hover:bg-pri-500 hover:text-white text-center"
            >
              Contact Us
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
