import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import TopBar from "../components/web/TopBar";
import NavBar from "../components/web/NavBar";
// import Toast from "../components/Toast";
import MessengerCustomerChat from "react-messenger-customer-chat";

function Web(props) {
  // const [message, setMessage] = useState({ s: "", t: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div className="h-full">
      <TopBar />
      <NavBar />
      {props.children}
      {/* <Toast message={props.message} /> */}
      <MessengerCustomerChat
        pageId="100720555013915"
        appId="<APP_ID>"
        // htmlRef="<REF_STRING>"
      />
    </div>
  );
}

export default withRouter(Web);
