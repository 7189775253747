import React from "react";
import FeatherIcon from "feather-icons-react";

function TopBar() {
  return (
    <div className="bg-pri-300 px-3 py-2 absolute w-full">
      <div className="container">
        {/* <div className="flex items-center justify-center sm:justify-between">
          <div className="text-white text-sm">
            Rebrand alert - Kodplex is now Upview
          </div>
          <div className="flex">
            <div className="text-sm text-white items-center mr-6 hidden md:flex">
              <FeatherIcon
                icon="map-pin"
                className="text-sec-500 w-4 h-4 mr-1"
              />
              Sri Lanka
            </div>
            <div className="text-sm text-white items-center mr-6 flex">
              <FeatherIcon icon="mail" className="text-sec-500 w-4 h-4 mr-1" />
              hello@upview.tech
            </div>
            <div className="text-sm text-white items-center hidden sm:flex">
              <FeatherIcon icon="phone" className="text-sec-500 w-4 h-4 mr-1" />
              +94 11 348 9595
            </div>
          </div>
        </div> */}
        <div className="text-white text-sm text-center uppercase">
          We've rebranded! <span className="text-sec-500">Kodplex</span> is now
          <span className="text-sec-500"> Upview</span>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
